import React from "react";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import { SimpleRollPage } from "../../components/";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <SimpleRollPage title={"Tags"}>
    <ul className="taglist">
      {group.map((tag) => (
        <li key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="link">
            <span>
              {tag.fieldValue} ({tag.totalCount})
            </span>
          </Link>
        </li>
      ))}
    </ul>
  </SimpleRollPage>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
